import React  from "react";

import Layout from "../components/Layout";
import LERegistrationForm from "../components/forms/LEregistrationForm";

const LEEmailSignup = () => {
  return (
    <>
      <LERegistrationForm />
    </>
  )
}

// language exchange "FREE ENTRY" signup form (that connects to mailChimp)
const IndexPage = () => {
  return (
    <Layout>
      <LEEmailSignup />
    </Layout>
  )
};

export default IndexPage;